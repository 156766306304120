import { User } from "../types/api/User";
import { api } from "./API";

export const searchUsers = async (term: string): Promise<Array<User>> => {
    const res = await api(`/search/users/${term}`)

    if (!res.ok) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Array<User>>

    try {
        resJSON = await res.json() as Promise<Array<User>>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací uživatelů: ' + e)
    }

    return resJSON
}

export const getUser = async (userID: string): Promise<User> => {
    const res = await api(`/users/${userID}`)

    if (!res.ok) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<User>

    try {
        resJSON = await res.json() as Promise<User>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací uživatele: ' + e)
    }

    return resJSON
}