import React, { useCallback, useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline, GoogleLogout } from 'react-google-login';
import { FetchingUserContext, handleGoogleLoginSuccess, handleGoogleLogout, UserContext } from '../../functions/Auth'
import { User } from '../../types/api/User';
import { Notification } from '../atoms/Notification'
import { Button, DeleteButton } from '../atoms/Button';

export const Navigation = () => {
    const user: User | undefined = useContext(UserContext)
    const fetchingUser: boolean = useContext(FetchingUserContext)

    const [loginError, setLoginError] = useState<any | undefined>()
    const unsetLoginError = useCallback(() => setLoginError(undefined), [])

    const [authInProgress, setAuthInProgress] = useState<boolean>(false)

    const googleLoginSuccess = useCallback(async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        await handleGoogleLoginSuccess(response)
        window.location.reload()
    }, [])

    const googleLogoutSuccess = useCallback(async () => {
        await handleGoogleLogout()
        window.location.reload()
    }, [])

    const googleFailure = useCallback((anything?: any) => {
        console.error(anything)
        setLoginError(anything)
        setAuthInProgress(false)
    }, [])

    return <nav className="navbar">
        <div className="navbar-brand">
            <NavLink to='/' className="navbar-item" activeClassName="is-tab is-active" exact>Zván Fin Management</NavLink>
        </div>
        <menu className="navbar-menu is-active">
            <div className="navbar-start">
                {user && <>
                    <NavLink to='/faktury' className="navbar-item" activeClassName="is-active is-tab">Faktury</NavLink>
                    <a href="https://storage.cloud.google.com/zvan-el/electr.jpg" target="_blank" className="navbar-item">Elektrárna</a>
                </>}
            </div>
            <div className="navbar-end">
                {loginError && <Notification>
                    <DeleteButton onClick={unsetLoginError} />
                    Chyba při přihlašování: <pre>{JSON.stringify(loginError, undefined, ' ')}</pre>
                </Notification>}
                {fetchingUser &&
                    <div className="navbar-item">
                        <Button loading={true}>...</Button>
                    </div>
                }
                <div className="navbar-item">
                    {user
                        ? <GoogleLogout
                            render={renderProps => {
                                const x = () => {
                                    setAuthInProgress(true)
                                    renderProps.onClick()
                                }
                                return <Button light={true} loading={authInProgress} onClick={x}>{user.Name} - Odhlásit</Button>
                            }}
                            clientId='691356852028-rcnanpkadnp21ghiet9b0o46t2v8jqm7.apps.googleusercontent.com'
                            buttonText={`Odhlásit uživatele ${user.Name}`} onLogoutSuccess={googleLogoutSuccess}
                            onFailure={googleFailure} />
                        : <GoogleLogin
                            render={renderProps => {
                                const x = () => {
                                    setAuthInProgress(true)
                                    renderProps.onClick()
                                }
                                return <Button light={!fetchingUser} loading={authInProgress} onClick={x}>
                                    {fetchingUser ? 'Ověřování přihlášení...' : 'Přihlásit'}
                                </Button>
                            }}
                            clientId='691356852028-rcnanpkadnp21ghiet9b0o46t2v8jqm7.apps.googleusercontent.com'
                            buttonText='Přihlásit' onSuccess={googleLoginSuccess}
                            onFailure={googleFailure} />
                    }
                </div>
            </div>
        </menu>
    </nav>
}
