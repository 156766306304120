import React, { useCallback, useMemo } from 'react'
import { FormInput, FormField, FormControl } from '../atoms/Form'
import { Participant } from '../../types/api/Invoice'
import { MultilineColumns } from './MultilineColumns'
import { calculateParticipantShares } from '../../functions/Invoice'
import { monetaryRound } from '../../functions/Monetary'
import { Button } from '../atoms/Button'
import { OmniUser } from './OmniUser'

interface Props {
    onChangeLine: ((user: Participant, value: string) => void)
    participants: Array<Participant>
    itemTotal: number
}

export const ParticipantAssigner = (props: Props) => {
    const { onChangeLine, participants, itemTotal } = props

    const changeParticipant = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const userID = event.currentTarget.dataset.extra

        onChangeLine({ UserID: userID } as Participant, event.target.value)
    }, [onChangeLine])

    const shares = useMemo(() => calculateParticipantShares(participants, itemTotal), [participants, itemTotal])

    return <MultilineColumns>
        {participants.map(participant => {
            const helpText = `${monetaryRound((shares.get(participant.UserID) || [0, 0])[1] || 0)} CZK`
            return <FormField label={<OmniUser userID={participant.UserID} />} helpText={helpText} key={participant.UserID + participant.InvoiceItemID}>
                <FormField addons={true}>
                    <FormControl>
                        <FormInput type='number' value={participant.Share} onChange={changeParticipant} extra={participant.UserID} />
                    </FormControl>
                    <FormControl>
                        <Button nonInteractive={true}>%</Button>
                    </FormControl>
                </FormField>
            </FormField>
        })}
    </MultilineColumns >
}