import { User } from "../types/api/User";
import { getUser } from "./UserAPI";

export const omniUser = (userID: string): User => {
    const storageKey = `user:${userID}`
    const storedUser = localStorage.getItem(storageKey)

    let r = {} as User

    if (!storedUser) {
        r = { ID: userID, Name: userID } as User
        getUser(userID).then(res => localStorage.setItem(storageKey, JSON.stringify(res)))
    } else {
        r = JSON.parse(storedUser) as User || { ID: 'Loading', Name: `Nacitani ${userID}`, PictureURL: '' } as User
    }

    return r
}

export const omniUserAsync = async (userID: string): Promise<User> => {
    const storageKey = `user:${userID}`
    const storedUser = localStorage.getItem(storageKey)

    let r: User

    if (!storedUser) {
        r = await getUser(userID)
        localStorage.setItem(storageKey, JSON.stringify(r))
    } else {
        r = JSON.parse(storedUser) as User || { ID: 'Loading', Name: `Nacitani ${userID}`, PictureURL: '' } as User
    }

    return r
}