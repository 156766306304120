import React, { useState, useCallback, useEffect } from 'react'
import { searchUsers } from '../../functions/UserAPI'
import { User } from '../../types/api/User'
import { Panel } from '../molecules/Panel'
import { FormInput, FormControl } from '../atoms/Form'

interface Props {
    userSelect: ((user: User) => void)
    heading?: string
    filteredUsers?: Array<User>
}

export const UserSearchPanel = (props: Props) => {
    const { userSelect, heading } = props

    const [fetchingInProgress, setFetchingInProgress] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState<string>('docasne')
    const [searchedUsers, setSearchedUsers] = useState<Array<User>>()

    useEffect(() => {
        (async () => {
            // if (searchTerm && searchTerm !== '') {
            setFetchingInProgress(true)
            const res = await searchUsers(searchTerm)
            setSearchedUsers(res)
            setFetchingInProgress(false)
        })()
    }, [searchTerm])

    const onChangeSearchTerm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        setSearchTerm(event.target.value)
    }, [])

    const selectUser = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()

        const user = searchedUsers?.find((u) => u.ID === (event.currentTarget)?.title)

        user && userSelect(user)
    }, [userSelect, searchedUsers])

    return <Panel heading={heading}>
        <div>
            <FormControl loading={fetchingInProgress}>
                <FormInput value={searchTerm} onChange={onChangeSearchTerm} placeholder="Hledej..." />
            </FormControl>
        </div>

        {searchedUsers && searchedUsers.map(user =>
            <a href={user.ID} title={user.ID} onClick={selectUser} key={user.ID}>
                {user.Name}
            </a>
        )}
    </Panel>
}