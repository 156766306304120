import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { MainWrapper } from '../components/templates/MainWrapper'
import { useParams } from 'react-router-dom'
import { Title } from '../components/atoms/Title'
import { Columns } from '../components/molecules/Columns'
import { ParticipantAssigner } from '../components/organisms/ParticipantAssigner'
import { FormInput, FormField, FormControl, FormInputTextarea } from '../components/atoms/Form'
import { Button, DeleteButton, LinkButton } from '../components/atoms/Button'
import { Level, LevelHeadings } from '../components/atoms/Level'
import { HorizontalDivider } from '../components/atoms/HorizontalDivider'
import { PulledRight } from '../components/atoms/PulledRight'
import { Invoice, Item, Participant } from '../types/api/Invoice'
import { getInvoice, postItem, putParticipants } from '../functions/InvoiceAPI'
import { Notification } from '../components/atoms/Notification'
import { useQuery } from '../functions/URL'
import { monetaryRound } from '../functions/Monetary'
import { calculateItemTotal } from '../functions/Invoice'

export const ItemForm = () => {
    const { invoiceID } = useParams<{ invoiceID: string }>()

    const urlQuery = useQuery()
    const defaultParticipants = useMemo(() => {
        const participantsQuery = urlQuery.get('ucastnici')

        if (participantsQuery !== null) {
            const participantsIDArray = participantsQuery.split(',')

            let participantsArray: Array<Participant> = []

            for (const pI of participantsIDArray) {
                participantsArray.push({ UserID: pI, Share: 100 } as Participant)
            }

            return participantsArray.reverse()
        }

        return []
    }, [urlQuery])

    const [submittingInProgress, setSubmittingInProgress] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string>()
    const onSubmitErrorReset = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setSubmitError(undefined)
    }, [])

    const [invoice, setInvoice] = useState<Invoice>()
    useEffect(() => {
        (async () => {
            if (!invoice) {
                let res: Invoice

                try {
                    res = await getInvoice(invoiceID)
                } catch (e) {
                    setSubmitError('Získání faktury selhalo: ' + e)
                    res = {} as Invoice
                }

                setInvoice(res)
            }
        })()
    }, [invoice, invoiceID])

    const [name, setName] = useState<string>('')
    const onChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setName(event.target.value)
    }, [])

    const [count, setCount] = useState<number>(1)
    const onChangeCount = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const n = parseFloat(event.target.value)
        if (isNaN(n) || n < 0) {
            setCount(1)
            return
        }
        setCount(n)
    }, [])

    const [price, setPrice] = useState<number>(0)
    const onChangePrice = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const n = parseFloat(event.target.value)
        if (isNaN(n)) {
            setPrice(0)
            return
        }
        setPrice(n)
    }, [])

    const [discount, setDiscount] = useState<number>(0)
    const onChangeDiscount = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const n = parseFloat(event.target.value)
        if (isNaN(n)) {
            setDiscount(0)
            return
        }
        setDiscount(n)
    }, [])

    const [note, setNote] = useState<string>()
    const onChangeNote = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        setNote(event.target.value)
    }, [])

    const [final, setFinal] = useState<boolean>(false)
    const onChangeFinal = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setFinal(!final)
    }, [final])

    const total = useMemo(() => calculateItemTotal(count, price, discount), [count, price, discount])

    const percentageOfTotal = useMemo(() => {
        if (invoice && total) {
            return total / invoice.Checksum * 100
        }
        return 0
    }, [invoice, total])

    const [participants, setParticipants] = useState<Array<Participant>>(defaultParticipants)
    const setParticipant = useCallback((participant: Participant, value: string) => {
        const participantsTemp = [...participants]

        const participantIndex = participants.findIndex(p => p.UserID === participant.UserID)

        participantsTemp[participantIndex].Share = parseFloat(value) || 0

        setParticipants(participantsTemp)
    }, [participants])

    const setAllParticipantsToZero = useCallback(() => {
        const participantsTemp = [...participants]

        participantsTemp.map(p => p.Share = 0)

        setParticipants(participantsTemp)
    }, [participants])

    const onSubmit = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        setSubmittingInProgress(true)

        if (count <= 0) {
            setSubmitError('Počet jednotek musí být vyšší než 0')
            setSubmittingInProgress(false)
            return
        }

        if (price < 0) {
            setSubmitError('Cena za jednotku nesmí být negativní')
            setSubmittingInProgress(false)
            return
        }

        if (name === '') {
            setSubmitError('Název položky musí být vyplněn')
            setSubmittingInProgress(false)
            return
        }


        if (participants.find(p => p.Share > 0) === undefined) {
            setSubmitError('Alespoň jeden účastník musí mít ne-nulový podíl')
            setSubmittingInProgress(false)
            return
        }

        const item: Item = {
            Amount: count,
            Discount: - Math.abs(discount),
            Name: name,
            Price: price,
            Note: note,
            Final: final
        } as Item

        let res: Item

        try {
            res = await postItem(invoiceID, item)
        } catch (e) {
            setSubmitError('Odeslání položky selhalo: ' + e)
            setSubmittingInProgress(false)
            return
        }

        if (res.ID === '') {
            setSubmitError('Odeslání položky selhalo: Prázdná odpověď')
            setSubmittingInProgress(false)
            return
        }

        try {
            await putParticipants(res.ID, participants)
        } catch (e) {
            setSubmitError('Nastavení účastníků položky selhalo (položka jako taková byla ale odesláná úspěšně): ' + e)
            setSubmittingInProgress(false)
            return
        }

        setSubmittingInProgress(false)

        window.location.reload()
    }, [count, discount, name, price, note, invoiceID, participants, final])

    return <MainWrapper>
        <Title>Zadání fakturové položky</Title>
        <div className="subtitle">Faktura ID {invoiceID}</div>
        <Columns>
            <div>
                <FormField label="Název položky">
                    <FormControl>
                        <FormInput value={name} onChange={onChangeName} placeholder="Pivo? :)" />
                    </FormControl>
                </FormField>
                <Columns>
                    <FormField label="Počet jednotek" helpText="Musí být vyšší než 0">
                        <FormField addons={true}>
                            <FormControl expanded={true}>
                                <FormInput type='number' value={count} onChange={onChangeCount} />
                            </FormControl>
                            <FormControl>
                                <Button nonInteractive={true}>x</Button>
                            </FormControl>
                        </FormField>
                    </FormField>
                    <FormField label="Cena za jednotku">
                        <FormField addons={true}>
                            <FormControl expanded={true}>
                                <FormInput type='number' value={price} onChange={onChangePrice} />
                            </FormControl>
                            <FormControl>
                                <Button nonInteractive={true}>CZK</Button>
                            </FormControl>
                        </FormField>
                    </FormField>
                    <FormField label="Celková sleva" helpText="Hodnota bude použita jako negativní">
                        <FormField addons={true}>
                            <FormControl expanded={true}>
                                <FormInput type='number' value={discount} onChange={onChangeDiscount} />
                            </FormControl>
                            <FormControl>
                                <Button nonInteractive={true}>CZK</Button>
                            </FormControl>
                        </FormField>
                    </FormField>
                </Columns>
            </div>
            <FormField label="Poznámka">
                <FormInputTextarea value={note} onChange={onChangeNote} />
            </FormField>
        </Columns>
        <HorizontalDivider />
        <FormField>
            <Button type='info' onClick={setAllParticipantsToZero}>Nastavit vše na 0</Button>
        </FormField>
        <ParticipantAssigner onChangeLine={setParticipant} participants={participants} itemTotal={total} />
        <HorizontalDivider />
        {submitError && <Notification><DeleteButton onClick={onSubmitErrorReset} />{submitError}</Notification>}
        <PulledRight>
            <FormField grouped={true}>
                <FormControl>
                    {(!final && <Button type='info' onClick={onChangeFinal} outlined={true}>Finalizovat</Button>)
                        || <Button type='info' onClick={onChangeFinal}>Definalizovat</Button>}
                </FormControl>
                <FormControl>
                    <LevelHeadings title={`${monetaryRound(total)} CZK`} />
                </FormControl>
                <FormControl>
                    <LinkButton to={`/faktury/${invoiceID}`} type='link'>Zpět na fakturu</LinkButton>
                </FormControl>
                <FormControl>
                    <Button type='primary' onClick={onSubmit} loading={submittingInProgress}>Zadat</Button>
                </FormControl>
            </FormField>
        </PulledRight>
        <div>&nbsp;</div>
        <HorizontalDivider />
        {invoice && <>
            <Level>
                <LevelHeadings heading="Kontrolní součet položek faktury" title={`${monetaryRound(invoice.Checksum)} CZK`} />
                <LevelHeadings heading="Tato položka je" title={`${monetaryRound(percentageOfTotal)} %`} bottomHeading="z kont. součtu faktury" />
            </Level>
        </>}
    </MainWrapper>
}
