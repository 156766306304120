import React from 'react'

interface Props {
    children?: React.ReactNode
    className?: string
}

export const Tags = (props: Props) => {
    const { children, className } = props

    return <div className={className ? `tags ${className}` : 'tags'}>
        {children && React.Children.map(children, child =>
            React.isValidElement(child) && React.cloneElement(child, { className: 'tag' })
        )}
    </div>
}