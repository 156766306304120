import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

interface ButtonProps {
    children?: React.ReactNode
    type?: 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger' |
    'white' | 'black' | 'light' | 'dark' | 'text'
    light?: boolean
    size?: 'small' | 'normal' | 'medium' | 'large'
    disabled?: boolean
    hovered?: boolean
    focused?: boolean
    active?: boolean
    loading?: boolean
    nonInteractive?: boolean
    rounded?: boolean
    inverted?: boolean,
    outlined?: boolean,
    fullwidth?: boolean,
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
}

export const Button = (props: ButtonProps) => {
    const {
        children, type, light, size, disabled, hovered, focused, active,
        loading, nonInteractive, rounded, inverted, outlined, fullwidth,
        onClick    } = props

    const styles = useMemo(() => {
        const s = [
            'button',
            (type && `is-${type}`),
            (light && 'is-light'),
            (size && `is-${size}`),
            (hovered && 'is-hovered'),
            (focused && 'is-focused'),
            (active && 'is-active'),
            (loading && 'is-loading'),
            (nonInteractive && 'is-static'),
            (rounded && 'is-rounded'),
            (inverted && 'is-inverted'),
            (outlined && 'is-outlined'),
            (fullwidth && 'is-fullwidth'),
        ]
        return s.join(' ')
    }, [type, light, size, hovered, focused, active, loading, nonInteractive, rounded, inverted, outlined, fullwidth])

    return <button className={styles} onClick={onClick} disabled={disabled || nonInteractive}>{children}</button>
}

interface DeleteButtonProps {
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    title?: string
}

export const DeleteButton = (props: DeleteButtonProps) => {
    const { onClick, title } = props

    return <button className='delete' onClick={onClick} title={title} />
}

interface LinkButtonProps {
    children?: React.ReactNode
    type?: 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger' |
    'white' | 'black' | 'light' | 'dark' | 'text'
    light?: boolean
    size?: 'small' | 'normal' | 'medium' | 'large'
    hovered?: boolean
    focused?: boolean
    active?: boolean
    loading?: boolean
    nonInteractive?: boolean
    rounded?: boolean
    inverted?: boolean,
    outlined?: boolean,
    fullwidth?: boolean,
    to: string
}

export const LinkButton = (props: LinkButtonProps) => {
    const {
        children, type, light, size, hovered, focused, active, to,
        loading, nonInteractive, rounded, inverted, outlined, fullwidth
    } = props

    const styles = useMemo(() => {
        const s = [
            'button',
            (type && `is-${type}`),
            (light && 'is-light'),
            (size && `is-${size}`),
            (hovered && 'is-hovered'),
            (focused && 'is-focused'),
            (active && 'is-active'),
            (loading && 'is-loading'),
            (nonInteractive && 'is-static'),
            (rounded && 'is-rounded'),
            (inverted && 'is-inverted'),
            (outlined && 'is-outlined'),
            (fullwidth && 'is-fullwidth')
        ]
        return s.join(' ')
    }, [type, light, size, hovered, focused, active, loading, nonInteractive, rounded, inverted, outlined, fullwidth])

    return <Link to={to} className={styles}>{children}</Link>
}