import React from "react"
import { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login"
import { User } from "../types/api/User"
import { api } from "./API"

export const UserContext = React.createContext<User | undefined>(undefined)
export const FetchingUserContext = React.createContext<boolean>(false)

export const handleGoogleLoginSuccess = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    return api('/auth/login', 'POST', (response as GoogleLoginResponse).tokenId)
        .then(res => {
            if (res.status === 404) {
                alert(`Tento uživatel nelze přihlásit (není registrován)`)
                return
            }
            if (!res.ok) {
                alert(`Nastala chyba při přihlašování (${res.status} ${res.statusText})`)
                throw new Error(res.statusText)
            }
        })
        .catch((err: any) => {
            console.error(err)
            throw new Error(err)
        })
}

export const handleGoogleLogout = async () => {
    return api('/auth/logout', 'DELETE')
        .catch((err: any) => {
            console.error(err)
            throw new Error(err)
        })
}

export const whoami = async (): Promise<User | undefined> => {
    const res = await api('/auth/whoami')

    if (res.status === 401) {
        return undefined
    }

    if (!res.ok) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<User>

    try {
        resJSON = await res.json() as Promise<User>
    } catch (e) {
        throw new Error('Chyba při zpracovávání infa o uživateli: ' + e)
    }

    return resJSON
}