import React from 'react'

interface Props {
    children: React.ReactNode
}

export const Columns = (props: Props) => {
    const { children } = props

    return <div className="columns">
        {React.Children.map(children, child => <div className="column">{child}</div>)}
    </div>
}