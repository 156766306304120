export const getTodaysISODate = () => {
    const date = new Date()

    const dateString = date.getUTCFullYear() + '-' + pad((date.getUTCMonth() + 1)) + '-' + pad((date.getUTCDate()))

    return dateString
}

function pad(n: number) {
    if (n < 10) {
        return '0' + n;
    }
    return n;
}