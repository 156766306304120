import React from 'react'

interface Props {
    heading?: string
    children?: React.ReactNode
}

export const Panel = (props: Props) => {
    const { heading, children } = props

    return <div className="panel">
        {heading && <div className="panel-heading">{heading}</div>}
        {children && React.Children.map(children, child =>
            React.isValidElement(child) && React.cloneElement(child, { className: 'panel-block' })
        )}
    </div>
}