import React from 'react'

interface LevelProps {
    children?: React.ReactNode
}

export const Level = (props: LevelProps) => {
    const { children } = props

    return <div className="level">
        {children}
    </div>
}

interface LevelItemProps {
    children: React.ReactNode
}

export const LevelItem = (props: LevelItemProps) => {
    const { children } = props

    return <div className="level-item">
        {children}
    </div>
}

interface LevelHeadingProps {
    heading?: string | number
    title?: string | number
    bottomHeading?: string | number
}

export const LevelHeadings = (props: LevelHeadingProps) => {
    const { heading, title, bottomHeading } = props

    return <div className="level-item has-text-centered">
        <div>
            {heading && <p className="heading">{heading}</p>}
            {title && <p className="title">{title}</p>}
            {bottomHeading && <p className="heading">{bottomHeading}</p>}
        </div>
    </div>
}