
import React, { useState, useEffect, useMemo } from 'react'
import { Notification } from '../components/atoms/Notification'
import { Link, useParams } from 'react-router-dom'
import { Invoice, Item, Participant } from '../types/api/Invoice'
import { MainWrapper } from '../components/templates/MainWrapper'
import { Button, LinkButton } from '../components/atoms/Button'
import { getInvoice, getInvoiceItems, getParticipantsBatch } from '../functions/InvoiceAPI'
import { Table } from '../components/atoms/Table'
import { Level, LevelItem } from '../components/atoms/Level'
import { monetaryRound } from '../functions/Monetary'
import { calculateItemTotal, calculateParticipantShares } from '../functions/Invoice'
import { InvoiceMetadata } from '../components/molecules/InvoiceMetadata'
import { HorizontalDivider } from '../components/atoms/HorizontalDivider'

import '../styles/print.css'
import { PrintArea } from '../components/atoms/PrintArea'
import { OmniUser } from '../components/organisms/OmniUser'

export const InvoiceView = () => {
    const [invoiceFetchError, setInvoiceFetchError] = useState<string>()
    const [invoiceItemsFetchError, setInvoiceItemsFetchError] = useState<string>()
    const [invoiceParticipantsFetchError, setInvoiceParticipantsFetchError] = useState<string>()
    const { invoiceID } = useParams<{ invoiceID: string }>()

    const [invoice, setInvoice] = useState<Invoice>()
    useEffect(() => {
        (async () => {
            let res: Invoice

            try {
                res = await getInvoice(invoiceID)
            } catch (e) {
                setInvoiceFetchError('Chyba při získávání faktury: ' + e)
                res = {} as Invoice
            }

            if (res) {
                setInvoice(res)
            }
        })()
    }, [invoiceID])

    const [invoiceItems, setInvoiceItems] = useState<Array<Item>>()
    useEffect(() => {
        (async () => {
            let res: Array<Item>

            try {
                res = await getInvoiceItems(invoiceID)
            } catch (e) {
                setInvoiceItemsFetchError('Chyba při získávání položek faktury: ' + e)
                res = []
            }

            if (res) {
                setInvoiceItems(res)
            }
        })()
    }, [invoiceID])

    const [invoiceParticipants, setInvoiceParticipants] = useState<Array<Participant>>()
    useEffect(() => {
        (async () => {
                let res: Array<Participant>

                try {
                    res = await getParticipantsBatch(invoiceID)
                } catch (e) {
                    setInvoiceParticipantsFetchError('Chyba při získávání informací o účastnících faktury: ' + e)
                    res = []
                }

                setInvoiceParticipants(res)
        })()
    }, [invoiceID])

    const [calculatedTotalForAllItems, setCalculatedTotalForAllItems] = useState<number>(0)
    const [calculatedTotalForItem, setCalculatedTotalForItem] = useState<Map<string, number>>()
    // Map<ParticipantID, Map<ItemID, [percentage, calculated]>>
    const [calculatedItemTotalForParticipant, setCalculatedItemTotalForParticipant] = useState<Map<string, Map<string, [number, number]>>>()
    const [calculatedTotalForParticipant, setCalculatedTotalForParticipant] = useState<Map<string, number>>()
    const participantIDs = useMemo(() => calculatedTotalForParticipant && [...calculatedTotalForParticipant?.keys()], [calculatedTotalForParticipant])
    useEffect(() => {
        if (invoiceItems && invoiceParticipants) {
            let total = 0
            let itemMap = new Map<string, number>()
            let participantItemMap = new Map<string, Map<string, [number, number]>>()
            let participantMap = new Map<string, number>()

            for (const item of invoiceItems) {
                const itemTotal = calculateItemTotal(item.Amount, item.Price, item.Discount)
                total += itemTotal
                itemMap.set(item.ID, itemTotal)
            }

            for (const item of invoiceItems) {
                const itemParticipants = invoiceParticipants.filter(p => p.InvoiceItemID === item.ID)
                const itemTotal = itemMap.get(item.ID) || 0

                const shares = calculateParticipantShares(itemParticipants, itemTotal)
                for (const participantID of shares.keys()) {
                    const preP = participantItemMap.get(participantID) || participantItemMap.set(participantID, new Map<string, [number, number]>()).get(participantID)!
                    preP.get(item.ID) || preP.set(item.ID, shares.get(participantID)!)
                }
            }

            for (const participantID of participantItemMap.keys()) {
                const item = participantItemMap.get(participantID)
                if (item) {
                    for (const itemID of item.keys()) {
                        const existingItem = item.get(itemID)
                        const calculatedShare = existingItem ? existingItem[1] : 0
                        const existingParticipant = participantMap.get(participantID) || 0
                        participantMap.set(participantID, existingParticipant + calculatedShare)
                    }
                }
            }

            setCalculatedTotalForAllItems(total)
            setCalculatedTotalForItem(itemMap)
            setCalculatedItemTotalForParticipant(participantItemMap)
            setCalculatedTotalForParticipant(participantMap)
        }
    }, [invoiceItems, invoiceParticipants])

    return <MainWrapper>
        <Level>
            <LevelItem>
                <LinkButton to={`/formulare/faktury/${invoiceID}`} outlined={true} type='link'>Upravit a přidat položky</LinkButton>
            </LevelItem>
            <LevelItem>
                <Button type='black' outlined={true} onClick={window.print}>Tisknout</Button>
            </LevelItem>
            <LevelItem>
                <Button type='danger' nonInteractive={true} outlined={true} onClick={() => { }}>Smazat</Button>
            </LevelItem>
        </Level>
        <HorizontalDivider />

        <PrintArea>
            {(invoiceFetchError && <Notification>{invoiceFetchError}</Notification>)
                || (invoice &&
                    <InvoiceMetadata invoice={invoice} calculatedTotal={calculatedTotalForAllItems}
                        itemsFinalizedOutOf={invoiceItems && [invoiceItems.reduce((a, b) => a + (b.Final ? 1 : 0), 0), invoiceItems.length]} />)
                || <Button loading={true} nonInteractive={true} size='large' fullwidth={true} />
            }

            {((invoiceItemsFetchError || invoiceParticipantsFetchError) && <Notification>{invoiceItemsFetchError || invoiceParticipantsFetchError}</Notification>)
                || ((participantIDs && invoiceItems && calculatedTotalForItem) && <>
                    <Table narrow={true} striped={true}
                        thead={<>
                            <tr>
                                <th colSpan={2}>Položky</th>
                                {participantIDs.map(participantID => <th key={participantID}><OmniUser userID={participantID} /></th>)}
                            </tr>
                            <tr>
                                <th>&nbsp;</th>
                                <th className="has-background-info">{monetaryRound(calculatedTotalForAllItems)}&nbsp;CZK</th>
                                {participantIDs.map(participantID => <th className="has-background-primary" key={participantID}>{monetaryRound(calculatedTotalForParticipant?.get(participantID) || 0)}&nbsp;CZK</th>)}
                            </tr>
                        </>}
                    >
                        {invoiceItems.map(item => <React.Fragment key={item.ID}>
                            <tr>
                                <th className={(item.Final && 'has-background-success') || ''}>{item.Name}</th>
                                <td className="has-background-info-light">{monetaryRound(calculatedTotalForItem.get(item.ID) || 0)}&nbsp;CZK</td>
                                {participantIDs.map(participantID => {
                                    const x = calculatedItemTotalForParticipant?.get(participantID)?.get(item.ID) || [0, 0]
                                    return <td key={participantID}>{monetaryRound(x[1])}&nbsp;CZK</td>
                                })}
                            </tr>
                            <tr>
                                <td><Link to={`/formulare/polozky/${item.ID}`}>Upravit</Link></td>
                                <td className="has-background-info-light"><i>{`${item.Amount} * ${item.Price} - ${Math.abs(item.Discount)}`}</i></td>
                                {participantIDs.map(participantID => {
                                    const x = calculatedItemTotalForParticipant?.get(participantID)?.get(item.ID) || [0, 0]
                                    return <td key={participantID}><i>{x[0]}&nbsp;%</i></td>
                                })}
                            </tr>
                            {item.Note && <tr>
                                <td colSpan={2 + participantIDs.length}><b>Poznámka k položce {item.Name}:</b> {item.Note}</td>
                                {/* validateDOMNesting( */}
                            </tr>}
                            <tr><td colSpan={2 + participantIDs.length}>&nbsp;</td></tr>
                        </React.Fragment>
                        )}
                    </Table>
                </>)
                || <Button loading={true} nonInteractive={true} size='large' fullwidth={true} />
            }
        </PrintArea>
    </MainWrapper >
}