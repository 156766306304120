import { APIEndpoint } from "../App"

type Methods = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'

export const api = async (path: string, method?: Methods, data?: string): Promise<Response> => {
    method = method || 'GET'

    return fetch(APIEndpoint + path, {
        method: method,
        body: data,
        credentials: 'include'
    })
}
