import React, { useMemo } from 'react'

interface TableProps {
    children: React.ReactNode
    thead?: React.ReactNode
    tfoot?: React.ReactNode
    striped?: boolean
    narrow?: boolean
}

export const Table = (props: TableProps) => {
    const { children, thead, tfoot, striped, narrow } = props

    const styles = useMemo(() => {
        const s = [
            'table',
            'is-fullwidth',
            'is-bordered',
            'is-hoverable',
            striped && 'is-striped',
            narrow && 'is-narrow'
        ]
        return s.join(' ')
    }, [narrow, striped])

    return <div className="table-container">
        <table className={styles}>
            {thead && <thead>{thead}</thead>}
            <tbody>
                {children}
            </tbody>
            {tfoot && <tfoot>{tfoot}</tfoot>}
        </table>
    </div>
}

interface TableKeyValueProps {
    head: React.ReactNode
    value: React.ReactNode
}

export const TableKeyValue = (props: TableKeyValueProps) => {
    const { head, value } = props

    return <tr>
        <th>{head}</th>
        <td>{value}</td>
    </tr>
}