import React from 'react'

interface Props {
    children: React.ReactNode
    subtitle?: string
}

export const Title = (props: Props) => {
    const {children, subtitle} = props

    if (!subtitle) {
        return <h1 className="title">{children}</h1>
    }

    return <div>
        <h1 className="title">{children}</h1>
        <h2 className="subtitle">{subtitle}</h2>
    </div>
}