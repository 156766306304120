import React, { useMemo } from 'react'

interface FormInputProps {
    placeholder?: string
    value?: string | number | readonly string[] | undefined
    type?: 'text' | 'date' | 'number'
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void)
    disabled?: boolean
    color?: 'primary' | 'info' | 'success' | 'warning' | 'danger'
    size?: 'small' | 'medium' | 'large'
    rounded?: boolean
    hovered?: boolean
    focused?: boolean
    nonInteractive?: boolean
    extra?: string
    tabIndex?: number
}

export const FormInput = (props: FormInputProps) => {
    const {
        placeholder, value, type, onChange, disabled, color, size, rounded,
        hovered, focused, nonInteractive, extra, tabIndex
    } = props

    const styles = useMemo(() => {
        const s = [
            'input',
            (type && 'is-' + type),
            (color && 'is-' + color),
            (size && 'is-' + size),
            (hovered && 'is-hovered'),
            (focused && 'is-focused'),
            (rounded && 'is-rounded'),
        ]
        return s.join(' ')
    }, [color, focused, hovered, rounded, size, type])

    return <input type={type || 'text'} className={styles}
    placeholder={placeholder || '...'} value={value} onChange={onChange}
    disabled={disabled} readOnly={nonInteractive} data-extra={extra} tabIndex={tabIndex} />
}

interface FormInputTextareaProps {
    value: string | number | readonly string[] | undefined
    onChange: ((event: React.ChangeEvent<HTMLTextAreaElement>) => void)
    disabled?: boolean
    fixedSize?: boolean
    size?: 'small' | 'medium' | 'large'
    hovered?: boolean
    focused?: boolean
    placeholder?: string
}

export const FormInputTextarea = (props: FormInputTextareaProps) => {
    const {
        value, onChange, disabled, fixedSize, size, hovered,
        focused, placeholder
    } = props

    const styles = useMemo(() => {
        const s = [
            'textarea',
            (size && 'is-' + size),
            (hovered && 'is-hovered'),
            (focused && 'is-focused'),
            (fixedSize && 'has-fixed-size')
        ]
        return s.join(' ')
    }, [size, hovered, focused, fixedSize])

    return <textarea className={styles} value={value} onChange={onChange}
    disabled={disabled} placeholder={placeholder || '...'} />
}

interface FormControlProps {
    children?: React.ReactNode
    expanded?: boolean
    loading?: boolean
}

export const FormControl = (props: FormControlProps) => {
    const { children, expanded, loading } = props

    const styles = useMemo(() => {
        const s = [
            'control',
            (expanded && 'is-expanded'),
            (loading && 'is-loading')
        ]

        return s.join(' ')
    }, [expanded, loading])

    return <div className={styles}>
        {children}
    </div>
}

interface FormFieldProps {
    label?: React.ReactNode
    helpText?: string
    children?: React.ReactNode
    addons?: boolean
    grouped?: boolean
}

export const FormField = (props: FormFieldProps) => {
    const {
        label, helpText, children, addons, grouped
    } = props

    const styles = useMemo(() => {
        const s = [
            'field',
            (addons && 'has-addons'),
            (grouped && 'is-grouped')
        ]

        return s.join(' ')
    }, [addons, grouped])

    return <div className={styles}>
        {label && <div className="label">{label}</div>}
        {children}
        {helpText && <div className="help">{helpText}</div>}
    </div>
}