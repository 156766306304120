import { Participant } from "../types/api/Invoice"

export const calculateParticipantShares = (participants: Array<Participant>, total: number): Map<string, [number, number]> => {
    // Get the smallest "fragment" of the total based on the "Participant"'s
    // "Share" percentages, so that the fragment can then be multiplied by the
    // shares/percentages
    const fragment = total / participants.reduce((a, b) => a + b.Share, 0)

    // Map each participant by string (User ID), the raw share number, and the
    // desired calculated fragment * share number
    let res = new Map<string, [number, number]>()
    for (const p of participants) {
        res.set(p.UserID, [p.Share, fragment * p.Share])
    }
    return res
}

export const calculateItemTotal = (count: number, price: number, discount: number): number => {
    // Calculate the total cost of an item based on count, price, and the discount.
    // The discount is taken as absolute, so that both -3 and 3 subtract 3 from the total.
    return count * price - Math.abs(discount)
}
