import React, { useState, useEffect, useCallback } from 'react'
import { MainWrapper } from '../components/templates/MainWrapper'
import { Invoice } from '../types/api/Invoice'
import { getInvoices } from '../functions/InvoiceAPI'
import { Notification } from '../components/atoms/Notification'
import { Button, LinkButton } from '../components/atoms/Button'
import { InvoicePane } from '../components/molecules/InvoicePane'
import { MultilineColumns } from '../components/organisms/MultilineColumns'
import { HorizontalDivider } from '../components/atoms/HorizontalDivider'
import { PulledRight } from '../components/atoms/PulledRight'

const PageSize = 10

export const Invoices = () => {
    const [fetchError, setFetchError] = useState<string>()
    const [endOfList, setEndOfList] = useState<boolean>(false)
    const [invoices, setInvoices] = useState<Array<Invoice>>([])
    const [page, setPage] = useState<number>(PageSize)

    const addPage = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        let res: Array<Invoice>

        try {
            res = await getInvoices(page)
        } catch (e) {
            setFetchError('Chyba při získávání seznamu faktur: ' + e)
            res = [] as Array<Invoice>
        }

        if (res.length < 10) {
            setEndOfList(true)
        }

        setInvoices(invoices && invoices.concat(res))
        setPage(page + PageSize)
    }, [invoices, page])

    useEffect(() => {
        (async () => {
            let res: Array<Invoice>

            try {
                res = await getInvoices(0)
            } catch (e) {
                setFetchError('Chyba při získávání seznamu faktur: ' + e)
                res = [] as Array<Invoice>
            }

            if (res.length > 0) {
                setInvoices(res)
            }
        })()
    }, [])

    return <MainWrapper>
        <nav className="navbar">
            <div className="navbar-brand">
                <span className="navbar-item">Akce</span>
            </div>
            <menu className="navbar-menu is-active">
                <div className="navbar-start">
                    <div className="navbar-item">
                        <LinkButton to='/formulare/faktury' type="primary">Zadání faktury</LinkButton>
                    </div>
                </div>
            </menu>
        </nav>
        <HorizontalDivider />
        {(fetchError && <Notification>{fetchError}</Notification>)
            || ((invoices && invoices.length > 0) && <>
                <MultilineColumns>
                    {invoices.map(invoice => <InvoicePane invoice={invoice} key={invoice.ID} />)}
                </MultilineColumns>
                {endOfList && <Notification>Dojeli jste na konec</Notification>}
                <PulledRight><Button type="info" onClick={addPage}>Další</Button></PulledRight>
            </>
            ) || ((invoices && invoices.length === 0) && <Notification level='warning'>Nebyly nalezeny žádné faktury</Notification>)
            || <Button loading={true} nonInteractive={true} size='large' fullwidth={true} />
        }
    </MainWrapper >
}