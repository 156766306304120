import React from 'react'

interface Props {
    children?: React.ReactNode
}

export const PulledRight = (props: Props) => {
    const { children } = props

    return <div className="is-clearfix">
        <div className="is-pulled-right">
            {children}
        </div>
    </div>
}