import React, { useEffect, useState } from 'react'
import { LinkButton } from '../components/atoms/Button'
import { HorizontalDivider } from '../components/atoms/HorizontalDivider'
import { Title } from '../components/atoms/Title'
import { MainWrapper } from '../components/templates/MainWrapper'
import { countInvoices } from '../functions/InvoiceAPI'
import { Notification } from '../components/atoms/Notification'

export const Overview = () => {
    const [fetchError, setFetchError] = useState<string>()

    const [invoiceCount, setInvoiceCount] = useState<number>(0)

    useEffect(() => {
        (async () => {
            let res: number

            try {
                res = await countInvoices()
            } catch (e) {
                setFetchError('Chyba při získávání počtu faktur: ' + e)
                res = 0
            }

            setInvoiceCount(res)
        })()
    }, [])

    return <MainWrapper>
        <nav className="navbar">
            <div className="navbar-brand">
                <span className="navbar-item">Zkratky</span>
            </div>
            <menu className="navbar-menu is-active">
                <div className="navbar-start">
                    <div className="navbar-item">
                        <LinkButton to='/formulare/faktury' type="primary">Formulář na faktury</LinkButton>
                    </div>
                </div>
            </menu>
        </nav>
        <HorizontalDivider />
        {fetchError && <Notification>{fetchError}</Notification>}
        <Title>Počet faktur: {invoiceCount}</Title>
    </MainWrapper>
}
