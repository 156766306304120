import React from 'react'
import { Invoice } from '../../types/api/Invoice';
import { Link } from 'react-router-dom';
import { OmniUser } from '../organisms/OmniUser';

interface Props {
    invoice: Invoice
}

export const InvoicePane = (props: Props) => {
    const { ID, ShopName, PurchaseDate, BuyerID } = props.invoice

    return <Link to={`/faktury/${ID}`}>
        <article className="card">
            <div className="card-content">
                <p className="title">{ShopName}</p>
                <p className="subtitle">{PurchaseDate}</p>
                <p className="content"><OmniUser userID={BuyerID} /></p>
            </div>
        </article>
    </Link>
}