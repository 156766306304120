import React, { useEffect, useState } from 'react'
import { omniUserAsync } from '../../functions/User'
import { User } from '../../types/api/User'

interface Props {
    userID: string
}

export const OmniUser = (props: Props) => {
    const [userName, setUserName] = useState<string>()
    useEffect(() => {
        (async () => {
            let res: User

            try {
                res = await omniUserAsync(props.userID)
            } catch (e) {
                res = { ID: props.userID, Name: props.userID }
                console.warn('Could not fetch user ID', props.userID)
            }

            setUserName(res.Name)
        })()
    }, [props.userID])

    return <>{userName ? userName : 'Načítání uživatele...'}</>
}