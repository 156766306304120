import React, { useEffect, useState } from 'react';
import { Switch, HashRouter as Router, Route } from 'react-router-dom';
import { Invoices } from './pages/Invoices';
import { InvoiceForm } from './pages/InvoiceForm';
import { InvoiceView } from './pages/InvoiceView';
import { ItemForm } from './pages/ItemForm';
import { FetchingUserContext, UserContext, whoami } from './functions/Auth';
import { User } from './types/api/User';
import { ItemEditForm } from './pages/ItemEditForm';
import { Overview } from './pages/Overview';

export const APIEndpoint = (process.env.NODE_ENV === 'development') ? 'http://localhost:8000' : 'https://invoice-splitter.appspot.com'

const App = () => {
    const [currentUser, setCurrentUser] = useState<User | undefined>()
    const [checkingLogin, setCheckingLogin] = useState<boolean>(false)
    useEffect(() => {
        (async () => {
            if (!currentUser) {
                setCheckingLogin(true)
                setCurrentUser(await whoami())
                setCheckingLogin(false)
            }
        })()
    }, [currentUser])

    return <Router>
        <FetchingUserContext.Provider value={checkingLogin}><UserContext.Provider value={currentUser}>
            <Switch>
                <Route exact={true} path='/' component={Overview} />
                <Route path='/faktury/:invoiceID' component={InvoiceView} />
                <Route path='/faktury' component={Invoices} />
                <Route path='/formulare/faktury/:invoiceID/polozky' component={ItemForm} />
                <Route path='/formulare/faktury/:invoiceID' component={InvoiceForm} />
                <Route path='/formulare/faktury' component={InvoiceForm} />
                <Route path='/formulare/polozky/:itemID' component={ItemEditForm} />
            </Switch>
        </UserContext.Provider></FetchingUserContext.Provider>
    </Router>
}

export default App
