import React, { useCallback } from 'react'

interface Props {
    children: React.ReactNode
    onClose: (() => void)
}

export const Modal = (props: Props) => {
    const { children, onClose } = props

    const onClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()

        onClose()
    }, [onClose])

    return <div className="modal is-active">
        {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <div className="modal-background" onClick={onClick}></div>
        <div className="modal-content">
            {children}
        </div>
    </div>
}