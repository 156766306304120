import React, { useMemo } from 'react'
import { monetaryRound } from '../../functions/Monetary';
import { Invoice } from '../../types/api/Invoice';
import { Box } from '../atoms/Box';
import { Table, TableKeyValue } from '../atoms/Table';
import { OmniUser } from '../organisms/OmniUser';
import { Columns } from './Columns';

interface Props {
    invoice: Invoice
    calculatedTotal: number
    itemsFinalizedOutOf?: [number, number]
}

export const InvoiceMetadata = (props: Props) => {
    const {invoice, calculatedTotal, itemsFinalizedOutOf} = props

    const percentFinalized = useMemo(() => {
        return (itemsFinalizedOutOf && itemsFinalizedOutOf[0] / itemsFinalizedOutOf[1] * 100) || 0
    }, [itemsFinalizedOutOf])

    return <>
        <Columns>
            <Table>
                <TableKeyValue head="Obchod" value={invoice.ShopName} />
                <TableKeyValue head="Nákupčí" value={<OmniUser userID={invoice.BuyerID} />} />
                <TableKeyValue head="Zakoupeno dne" value={invoice.PurchaseDate} />
                <TableKeyValue head="Kontrolní součet" value={`${monetaryRound(invoice.Checksum)} CZK`} />
                <TableKeyValue head="Počet položek" value={itemsFinalizedOutOf && itemsFinalizedOutOf[1]} />
            </Table>
            <Table>
                <TableKeyValue head="ID faktury" value={invoice.ID} />
                <TableKeyValue head="Zadavatel" value={<OmniUser userID={invoice.OwnerID} />} />
                <TableKeyValue head="Zadáno dne" value={invoice.CreatedAt} />
                <TableKeyValue head="Součet zadaných položek" value={`${monetaryRound(calculatedTotal)} CZK`} />
                <TableKeyValue head="Finalizace položek" value={itemsFinalizedOutOf && `${itemsFinalizedOutOf[0]} (${monetaryRound(percentFinalized)} %)`} />
            </Table>
        </Columns>
        <Box>
            {props.invoice.Note ? <><b>Poznámka</b>: {props.invoice.Note}</> : <i>Poznámka nebyla zadána</i>}
        </Box>
    </>
}