import { Invoice, Item, Participant } from "../types/api/Invoice"
import { api } from "./API"

export const getInvoices = async (page: number): Promise<Array<Invoice>> => {
    const res = await api(`/invoices?page=${page}`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Array<Invoice>>

    try {
        resJSON = await res.json() as Promise<Array<Invoice>>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací faktur: ' + e)
    }

    return resJSON
}

export const getInvoice = async (invoiceID: string): Promise<Invoice> => {
    const res = await api(`/invoices/${invoiceID}`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Invoice>

    try {
        resJSON = await res.json() as Promise<Invoice>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací faktury: ' + e)
    }

    return resJSON
}

export const getInvoiceItems = async (invoiceID: string): Promise<Array<Item>> => {
    const res = await api(`/invoices/${invoiceID}/items`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Array<Item>>

    try {
        resJSON = await res.json() as Promise<Array<Item>>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací položek faktury: ' + e)
    }

    return resJSON
}

export const getItem = async (itemID: string): Promise<Item> => {
    const res = await api(`/items/${itemID}`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Item>

    try {
        resJSON = await res.json() as Promise<Item>
    } catch (e) {
        throw new Error('Chyba při zpracovávání informací položek faktury: ' + e)
    }

    return resJSON
}

export const postInvoice = async (data: Invoice): Promise<Invoice> => {
    const res = await api('/invoices', 'POST', JSON.stringify(data))

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Invoice>

    try {
        resJSON = await res.json() as Promise<Invoice>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na fakturu: ' + e)
    }

    return resJSON
}

export const updateInvoice = async (data: Invoice, invoiceID: string): Promise<Invoice> => {
    const res = await api(`/invoices/${invoiceID}`, 'PATCH', JSON.stringify(data))

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Invoice>

    try {
        resJSON = await res.json() as Promise<Invoice>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na fakturu: ' + e)
    }

    return resJSON
}

export const countInvoices = async (): Promise<number> => {
    const res = await api(`/invoices-count`, `GET`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    const rawNum = await res.text()

    return +rawNum
}

export const postItem = async (invoiceID: string, data: Item): Promise<Item> => {
    const res = await api(`/invoices/${invoiceID}/items`, 'POST', JSON.stringify(data))

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Item>

    try {
        resJSON = await res.json() as Promise<Item>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na položku: ' + e)
    }

    return resJSON
}

export const updateItem = async (data: Item, itemID: string): Promise<Item> => {
    const res = await api(`/items/${itemID}`, 'PATCH', JSON.stringify(data))

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Item>

    try {
        resJSON = await res.json() as Promise<Item>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na položku: ' + e)
    }

    return resJSON
}

export const putParticipants = async (itemID: string, participants: Array<Participant>): Promise<void> => {
    const res = await api(`/items/${itemID}/participants`, 'PUT', JSON.stringify(participants))

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }
}

export const getParticipants = async (itemID: string): Promise<Array<Participant>> => {
    const res = await api(`/items/${itemID}/participants`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Array<Participant>>

    try {
        resJSON = await res.json() as Promise<Array<Participant>>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na účastníky: ' + e)
    }

    return resJSON
}

export const getParticipantsBatch = async (invoiceID: string): Promise<Array<Participant>> => {
    const res = await api(`/invoices/${invoiceID}/items/participants`)

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }

    let resJSON: Promise<Array<Participant>>

    try {
        resJSON = await res.json() as Promise<Array<Participant>>
    } catch (e) {
        throw new Error('Chyba při zpracovávání odkazových informací na účastníky: ' + e)
    }

    return resJSON
}

export const deleteItem = async (itemID: string) => {
    const res = await api(`/items/${itemID}`, 'DELETE')

    if (!res.ok || res.status !== 200) {
        throw new Error('Chyba při odesílání požadavku: ' + await res.text())
    }
}