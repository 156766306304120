import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MainWrapper } from '../components/templates/MainWrapper'
import { FormInput, FormField, FormControl, FormInputTextarea } from '../components/atoms/Form'
import { Invoice } from '../types/api/Invoice'
import { getInvoice, postInvoice, updateInvoice } from '../functions/InvoiceAPI'
import { getTodaysISODate } from '../functions/Time'
import { Title } from '../components/atoms/Title'
import { DeleteButton, Button } from '../components/atoms/Button'
import { Notification } from '../components/atoms/Notification'
import { Box } from '../components/atoms/Box'
import { Redirect, useParams } from 'react-router-dom'
import { User } from '../types/api/User'
import { Modal } from '../components/molecules/Modal'
import { UserSearchPanel } from '../components/organisms/UserSearchPanel'
import { Columns } from '../components/molecules/Columns'
import { Panel } from '../components/molecules/Panel'
import { Tags } from '../components/molecules/Tags'
import { HorizontalDivider } from '../components/atoms/HorizontalDivider'
import { PulledRight } from '../components/atoms/PulledRight'
import { omniUser } from '../functions/User'
import { UserContext } from '../functions/Auth'
import { CzechQuotes } from '../components/atoms/CzechQuotes'

export const InvoiceForm = () => {
    // If invoiceID is set it means that this page is invoked to edit the
    // metadata instead of submitting new
    const { invoiceID } = useParams<{ invoiceID: string }>()

    const currentUser = useContext(UserContext)

    const [submitError, setSubmitError] = useState<string>()
    const [submittingInProgress, setSubmittingInProgress] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<string>()
    const [buyerModalOpen, setBuyerModalOpen] = useState<boolean>(false)

    const [shopName, setShopName] = useState<string>('')
    const [purchaseDate, setPurchaseDate] = useState<string>(getTodaysISODate())
    const [buyer, setBuyer] = useState<User>()
    const [note, setNote] = useState<string>('')
    const [checksum, setChecksum] = useState<number>(0)

    const [initialParticipants, setInitialParticipants] = useState<Array<User>>([])

    const onChangeShopName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        setShopName(event.target.value)
    }, [])

    const onChangePurchaseDate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        setPurchaseDate(event.target.value)
    }, [])

    const onChangeChecksum = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const n = parseFloat(event.target.value)
        if (isNaN(n)) {
            setChecksum(0)
            return
        }
        setChecksum(n)
    }, [])

    const onChangeNote = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        setNote(event.target.value)
    }, [])

    const onOpenBuyerModal = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setBuyerModalOpen(true)
    }, [])

    const onCloseBuyerModal = useCallback(() => setBuyerModalOpen(false), [])

    const setBuyerAndCloseModal = useCallback((user: User) => {
        setBuyer(user)
        setBuyerModalOpen(false)
    }, [])

    const setBuyerToCurrentUser = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setBuyer(currentUser)
    }, [currentUser])

    const onSubmitErrorReset = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        setSubmitError(undefined)
    }, [])

    const addParticipant = useCallback((user: User) => {
        if (initialParticipants.find(participant => participant.ID === user.ID)) {
            return
        }
        setInitialParticipants(initialParticipants.concat(user))
    }, [initialParticipants])

    const removeParticipant = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        const participants = [...initialParticipants]

        const participantToRemove = participants.filter(iP => iP.ID === event.currentTarget.title)[0]

        const indexOfParticipantToRemove = participants.indexOf(participantToRemove)

        if (indexOfParticipantToRemove > -1) participants.splice(indexOfParticipantToRemove, 1)

        setInitialParticipants(participants)
    }, [initialParticipants])

    const onSubmit = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        setSubmittingInProgress(true)

        if (shopName === '') {
            setSubmitError('Není vyplněn název obchodu')
            setSubmittingInProgress(false)
            return
        }

        if (purchaseDate === '') {
            setSubmitError('Není vyplněno datum nákupu')
            setSubmittingInProgress(false)
            return
        }

        if (!buyer || buyer.ID === ``) {
            setSubmitError('Není správně vyplněno pole nákupčího')
            setSubmittingInProgress(false)
            return
        }

        if (initialParticipants.length < 1) {
            setSubmitError(`Není zadán ani jeden účastník. Pokud si přejete
            fakturu pouze ${invoiceID ? 'změnit' : 'přidat'} bez přidání
            položek, zvolte alespoň nějakého účastníka, odešlete tento
            formulář, a z formuláře na zadání položky odejděte bez jeho
            odeslání. Není to úplně super, o tom není debaty.`)
            setSubmittingInProgress(false)
            return
        }

        const invoice: Invoice = {
            PurchaseDate: purchaseDate,
            BuyerID: buyer.ID,
            ShopName: shopName,
            Note: note,
            Checksum: checksum
        } as Invoice

        let res: Invoice

        try {
            if (invoiceID) {
                res = await updateInvoice(invoice, invoiceID)
            } else {
                res = await postInvoice(invoice)
            }
        } catch (e) {
            setSubmitError('Odeslání faktury selhalo: ' + e)
            setSubmittingInProgress(false)
            return
        }

        setSubmittingInProgress(false)

        if (res.ID !== '') {
            setRedirect(`/formulare/faktury/${res.ID}/polozky?ucastnici=${initialParticipants.map(participant => participant.ID)}`)
        }
    }, [shopName, purchaseDate, buyer, initialParticipants, note, checksum, invoiceID])

    useEffect(() => {
        (async () => {
            if (invoiceID) {
                const invoiceData = await getInvoice(invoiceID)

                setShopName(invoiceData.ShopName)
                setPurchaseDate(invoiceData.PurchaseDate)
                setBuyer(omniUser(invoiceData.BuyerID))
                setNote(invoiceData.Note || '')
                setChecksum(invoiceData.Checksum)
            }
        })()
    }, [invoiceID])

    return <MainWrapper>
        {redirect && <Redirect to={redirect} />}

        <Title>{`${invoiceID ? 'Úprava' : 'Zadání'} faktury`}</Title>
        <Columns>
            <FormField label="Název obchodu">
                <FormControl>
                    <FormInput value={shopName} onChange={onChangeShopName} placeholder="Pravděpodobně Makro" />
                </FormControl>
            </FormField>
            <FormField label="Datum nákupu" helpText="Pozor na lokalizační formát! Může být zobrazen např. formát USA.">
                <FormControl>
                    <FormInput value={purchaseDate} type="date" onChange={onChangePurchaseDate} />
                </FormControl>
            </FormField>
        </Columns>
        {buyerModalOpen && <Modal onClose={onCloseBuyerModal}>
            <Box>
                <UserSearchPanel userSelect={setBuyerAndCloseModal} heading='Vyhledávání nákupčího uživatele' />
            </Box>
        </Modal>}
        <Columns>
            <FormField label="Nákupčí">
                <FormField addons={true}>
                    <FormControl expanded={true}>
                        <Button onClick={onOpenBuyerModal} fullwidth={true}>
                            {buyer ? <b title={`ID uživatele je ${buyer.ID}`}>{buyer.Name}</b> : <i>Nevybrán</i>}
                        </Button>
                    </FormControl>
                    {currentUser &&
                        <FormControl>
                            <Button type="info" light={true} onClick={setBuyerToCurrentUser}>Nastavit na <CzechQuotes>{currentUser.Name}</CzechQuotes></Button>
                        </FormControl>
                    }
                </FormField>
            </FormField>
            <FormField label="Kontrolní součet (nepovinné)">
                <FormField addons={true}>
                    <FormControl expanded={true}>
                        <FormInput type='number' value={checksum} onChange={onChangeChecksum} />
                    </FormControl>
                    <FormControl>
                        <Button nonInteractive={true}>CZK</Button>
                    </FormControl>
                </FormField>
            </FormField>
        </Columns>
        <HorizontalDivider />
        <Columns>
            <Panel heading="Účastníci">
                <Tags>
                    {initialParticipants && initialParticipants.map(participant => <span key={participant.ID}>
                        {participant.Name}
                        <DeleteButton onClick={removeParticipant} title={participant.ID} />
                    </span>)}
                </Tags>
            </Panel>
            <UserSearchPanel heading='Výběr účastníků' userSelect={addParticipant} filteredUsers={initialParticipants} />
        </Columns>
        <HorizontalDivider />
        <FormField label="Poznámka">
            <FormInputTextarea value={note} onChange={onChangeNote} />
        </FormField>
        <HorizontalDivider />
        {submitError && <Notification><DeleteButton onClick={onSubmitErrorReset} />{submitError}</Notification>}
        <PulledRight>
            <Button onClick={onSubmit} type="primary" loading={submittingInProgress}>Pokračovat</Button>
        </PulledRight>
    </MainWrapper >
}