import React from 'react'

interface Props {
    children: React.ReactNode
}

export const CzechQuotes = (props: Props) => {
    const {children} = props
    
    return <>&#8222;{children}&#8221;</>
}