import React from 'react'

interface Props {
    children: React.ReactNode
}

export const MultilineColumns = (props: Props) => {
    const { children } = props

    return <div className="columns is-multiline">
        {React.Children.map(children, child => <div className="column is-one-fifth">{child}</div>)}
    </div>
}