import React from 'react'

interface Props {
    children: React.ReactNode
    level?: 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger'
}

export const Notification = (props: Props) => {
    const { children, level } = props

    return <div className={`notification is-${level || 'danger'}`}>
        {children}
    </div>
}