import React, { ErrorInfo, useContext } from 'react'
import { Navigation } from '../organisms/Navigation'
import '../../styles/bulma.min.css'
import { HorizontalDivider } from '../atoms/HorizontalDivider'
import { Notification } from '../atoms/Notification'
import { UserContext } from '../../functions/Auth'
import { User } from '../../types/api/User'

export class MainWrapper extends React.Component<{}, { error?: string }> {
    constructor(props: React.PropsWithChildren<null>) {
        super(props)
        this.state = { error: undefined }
    }

    componentDidCatch(e: Error, info: ErrorInfo) {
        console.log(info)
        this.setState({ error: `${e.name}: ${e.message}` })
    }

    render() {
        return <div>
            <section className="container">
                <Navigation />
            </section>
            <HorizontalDivider />
            <section className="section">
                <main className="container">
                    {this.state.error
                        ? <Notification><h1 className="title">Nastala neočekávaná chyba.</h1>{this.state.error}</Notification>
                        : <AuthNoShow>{this.props.children}</AuthNoShow>}
                </main>
            </section>
        </div>
    }
}

const AuthNoShow = (props: { children: React.ReactNode }) => {
    const user: User | undefined = useContext(UserContext)

    return <>
        {user ? props.children : <div>Přihlašte se, prosím.</div>}
    </>

}